<div class="login">
    <div class="text-center">
        <img src="assets/img/logo.png" />
    </div>
    <h3 class="text-center">Acesse o sistema</h3>
    <form>
        <input #inputCompany (keydown)="$event.keyCode == 13 ? inputUsername.focus(): null" [(ngModel)]="customer" type="text" name="u" placeholder="Empresa" required="required" />
        <input #inputUsername (keydown)="$event.keyCode == 13 ? inputPassword.focus():null" [(ngModel)]="username" type="text" name="u" placeholder="Usuário" required="required" />
        <input #inputPassword (keydown)="$event.keyCode == 13 ? btnEnter.click():null" [(ngModel)]="password" type="password" name="p" placeholder="Senha" required="required" />
        <button #btnEnter (click)="enter()" type="button" class="btn btn-primary btn-block btn-large">Entrar</button>
        <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h4 class="alert-heading">Atenção!</h4>

            <p>Ocorreram alguns erros, veja abaixo:</p>
            <ul>
                <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
        </div>
    </form>
</div>