<input type="file" class="file-input" multiple (change)="onFileSelected($event)" #fileUpload>
<button class="file-upload" draggable="true" (click)="(uploadProgress ? '' : fileUpload.click())" (dragend)="onDragEndElement($event)">
    <fa-icon [icon]="faUpload" [ngStyle]="{'display': (uploadProgress || uploadError ? 'none' : 'block')}"></fa-icon>
    <fa-icon [icon]="faExclamationCircle" [ngStyle]="{'display': (uploadError ? 'block' : 'none'), 'color':'#e74c3c'}"></fa-icon>
    <div class="file-loading" [ngStyle]="{'display': (uploadProgress && !uploadError ? 'block' : 'none')}"> 
        <span class="file-progress">{{uploadProgress}}</span>
        <div class="lds-dual-ring" ></div>
    </div>
</button>
<button class="btn-keyboard" draggable="true" (click)="onKeyboardToggle()" (dragend)="onDragEndElement($event)">
    <fa-icon [icon]="faKeyboard"></fa-icon>
</button>
<div class="v-keyboard" id="v-keyboard" [ngStyle]="{'display': (screenKeyboardVisible ? 'block' : 'none')}"></div>
<div id="virti-display"></div>
<div *ngIf="errorMessage" class="modal visible" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Aviso</h5>
            </div>
            <div class="modal-body">
                <p>{{ errorMessage }}</p>
            </div>
            <div class="modal-footer">
                <button (click)="logoff()" type="button" class="btn btn-secondary" data-dismiss="modal">Sair</button>
                <button (click)="connectTunnel()" type="button" class="btn btn-primary">Tentar novamente</button>
            </div>
        </div>
    </div>
</div>
<app-toast [toastMessageFiles]="toastMessageFiles" [toastTitle]="toastTitle" (dragend)="onDragEndElement($event)"></app-toast>
<textarea id="clipboard_data" style="display: none;"></textarea>
<iframe id="pdf-frame" style="display: none;"></iframe>