<div id="toast" class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" draggable="true">
    <div class="toast-header">
        <strong class="mr-auto">{{toastTitle}}</strong>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true" (click)="closeToast()">×</span>
      </button>
    </div>
    <div class="toast-body" *ngFor="let msgFile of toastMessageFiles" [ngStyle]="{'color': (msgFile.isDone ? '#00b894' : 'auto')}">
        <strong>{{msgFile.name}}:</strong> {{msgFile.progress}}
    </div>
</div>