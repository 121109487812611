import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  username:string;

  state:boolean;

  constructor(private http: HttpClient) {}

  auth(
    customer: string,
    username: string,
    password: string
  ): Observable<Boolean> {
    return this.http
      .post(
        '/api/auth',
        { username: `${customer}:${username}`, password },
        { observe: 'response' }
      )
      .pipe(
        map((resp) => {
          this.username = username;
          var authorization = resp.headers.get('Authorization');
          this.storeBearer(authorization);
          return true;
        })
      );
  }

public getUserName():string{
  return this.username;
}

isState():boolean{
  return this.state;
}

 setState(state:boolean):void{
  this.state = state;
}

public setUserName(username:string):void{
   this.username = username;
}

  authWithToken() {
    var authorization = this.getToken();

    return this.http
      .post(
        '/api/auth',
        {},
        {
          observe: 'response',
          headers: {
            ['Authorization']: authorization,
          },
        }
      )
      .pipe(
        map((resp) => {
          var authorization = resp.headers.get('Authorization');
          this.storeBearer(authorization);
          return true;
        })
      );
  }

  storeBearer(authorization: string) {
    sessionStorage.setItem('X-Auth', authorization);
  }

  getToken(): string {
    return sessionStorage.getItem('X-Auth');
  }

  logoff() {
    sessionStorage.removeItem('X-Auth');
  }

  isAuthenticated():boolean {
    return sessionStorage.getItem('X-Auth') != null;
  }
}
