import { AuthService } from './../services/auth.service';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  customer = '';
  username = '';
  password = '';
  errors = [];
  public receivedMessages: string[] = [];

  subscriptions: Subscription[] = [];

  @ViewChild('inputCompany') inputCompany: ElementRef;

  constructor(private router: Router,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    if (environment.production) {

      // HACK: Don't log to console in production environment.
      // TODO: This can be done in better way using logger service and logger factory.
      if (window) {
        window.console.log = window.console.warn = window.console.info = function () {
          // Don't log anything.
        };
      }
    }
  }

  ngAfterViewInit() {
    this.inputCompany.nativeElement.focus();
  }

  enter() {
    this.errors = [];

    if (!this.username) {
      this.errors.push('Informe o usuário');
    }

    if (!this.password) {
      this.errors.push('Informe a senha');
    }

    if (!this.customer) {
      this.errors.push('Informe a empresa');
    }

    if (this.errors.length == 0) {
      this.authService.setUserName(this.username);
      this.authService
        .auth(this.customer, this.username, this.password)
        .subscribe(
          (resp) => {
            if (resp) {
              this.router.navigate(['home']);
            }
          },
          (error) => {
            console.log(error);
            this.errors.push('Usuário/senha inválidos');
          }
        );
    } else {
      this.inputCompany.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
